import { Union, Record } from "../../.fable/fable-library.3.2.9/Types.js";
import { union_type, class_type, record_type, option_type, string_type, bool_type, int32_type } from "../../.fable/fable-library.3.2.9/Reflection.js";
import { TransactionFilter, PagingResult$1$reflection, BalanceInformation$reflection, Transaction$reflection } from "../../Shared/Shared.js";
import { View_render as View_render_1, State_update as State_update_1, State_init as State_init_1, Types_Msg$reflection as Types_Msg$reflection_1, Types_State$reflection as Types_State$reflection_1 } from "./TopUp.js";
import { Deferred_map, Cmd_fromAsync, Deferred$1, AsyncOperationEvent$1, AsyncOperationEvent$1$reflection, Deferred$1$reflection } from "../Common/Extensions.js";
import { View_render as View_render_2, State_update as State_update_2, State_init as State_init_2, Types_Msg$reflection as Types_Msg$reflection_2, Types_State$reflection as Types_State$reflection_2 } from "../Common/Paging.js";
import { Cmd_map, Cmd_none, Cmd_OfFunc_result, Cmd_batch, Cmd_OfAsync_start, Cmd_OfAsyncWith_perform } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { createObj, int32ToString, partialApply } from "../../.fable/fable-library.3.2.9/Util.js";
import { api } from "./Communication.js";
import { singleton } from "../../.fable/fable-library.3.2.9/AsyncBuilder.js";
import { reverse, empty, singleton as singleton_1, map, ofArray } from "../../.fable/fable-library.3.2.9/List.js";
import { FSharpMap__get_Count, toList as toList_1, ofList, add } from "../../.fable/fable-library.3.2.9/Map.js";
import * as mc from "../../../../public/mc.svg";
import * as visa from "../../../../public/visa.svg";
import * as amex from "../../../../public/amex.svg";
import { defaultArg, map as map_1 } from "../../.fable/fable-library.3.2.9/Option.js";
import { Option, columns } from "../../.fable/Fulma.2.14.1/Layouts/Columns.fs.js";
import { TextAlignment_Option, Screen, Size_ISize, TextWeight_Option, Modifier_IModifier, Color_IColor } from "../../.fable/Fulma.2.14.1/Common.fs.js";
import { Option as Option_6, ISize, column } from "../../.fable/Fulma.2.14.1/Layouts/Column.fs.js";
import { printf, toText } from "../../.fable/fable-library.3.2.9/String.js";
import { Helpers_nothing } from "../../.fable/Fable.React.7.4.1/Fable.React.Helpers.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Option as Option_1, div } from "../../.fable/Fulma.2.14.1/Elements/Form/Control.fs.js";
import { Printer_sekM } from "../Common/Utils.js";
import { toString, specifyKind } from "../../.fable/fable-library.3.2.9/Date.js";
import { map as map_2, singleton as singleton_2, delay, toList } from "../../.fable/fable-library.3.2.9/Seq.js";
import { Option as Option_2, button } from "../../.fable/Fulma.2.14.1/Elements/Button.fs.js";
import { TableOption, table as table_2 } from "../../.fable/Fulma.2.14.1/Elements/Table.fs.js";
import { body, hero } from "../../.fable/Fulma.2.14.1/Layouts/Hero.fs.js";
import { Option as Option_3, container } from "../../.fable/Fulma.2.14.1/Layouts/Container.fs.js";
import { h1 } from "../../.fable/Fulma.2.14.1/Elements/Heading.fs.js";
import { Fa_IconOption, Fa_i } from "../../.fable/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { Interop_reactApi } from "../../.fable/Feliz.1.49.0/Interop.fs.js";
import { renderKeyValue } from "../Common/View.js";
import { Card_foot, Card_body, Card_title, Card_head, Card_card, background, Option as Option_4, modal } from "../../.fable/Fulma.2.14.1/Components/Modal.fs.js";
import { Option as Option_5, delete$ } from "../../.fable/Fulma.2.14.1/Elements/Delete.fs.js";
import { content } from "../../.fable/Fulma.2.14.1/Elements/Content.fs.js";
import { Helpers_combineClasses } from "../../.fable/Feliz.Bulma.2.16.0/ElementBuilders.fs.js";
import { Option as Option_7, switch$ } from "../../.fable/Fulma.Extensions.Wikiki.Switch.2.0.2/Switch.fs.js";
import { div as div_1 } from "../../.fable/Fulma.2.14.1/Elements/Form/Field.fs.js";

export class Types_Transaction extends Record {
    constructor(Id, Payment, IsMarkAsPaidInProgress, ReceiptId) {
        super();
        this.Id = (Id | 0);
        this.Payment = Payment;
        this.IsMarkAsPaidInProgress = IsMarkAsPaidInProgress;
        this.ReceiptId = ReceiptId;
    }
}

export function Types_Transaction$reflection() {
    return record_type("Balance.Types.Transaction", [], Types_Transaction, () => [["Id", int32_type], ["Payment", Transaction$reflection()], ["IsMarkAsPaidInProgress", bool_type], ["ReceiptId", option_type(string_type)]]);
}

export class Types_State extends Record {
    constructor(CustomerId, TopUp, Transactions, Paging, Information, ConfirmMarkAsPaidRequest, ShowTopupsOnly) {
        super();
        this.CustomerId = (CustomerId | 0);
        this.TopUp = TopUp;
        this.Transactions = Transactions;
        this.Paging = Paging;
        this.Information = Information;
        this.ConfirmMarkAsPaidRequest = ConfirmMarkAsPaidRequest;
        this.ShowTopupsOnly = ShowTopupsOnly;
    }
}

export function Types_State$reflection() {
    return record_type("Balance.Types.State", [], Types_State, () => [["CustomerId", int32_type], ["TopUp", Types_State$reflection_1()], ["Transactions", Deferred$1$reflection(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [int32_type, Types_Transaction$reflection()]))], ["Paging", Types_State$reflection_2()], ["Information", Deferred$1$reflection(BalanceInformation$reflection())], ["ConfirmMarkAsPaidRequest", option_type(Types_Transaction$reflection())], ["ShowTopupsOnly", bool_type]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["TransactionsLoaded", "LoadBalanceInformation", "MarkAsPaidRequested", "MarkAsPaidModalClose", "MarkAsPaid", "TopUpMsg", "PagingMsg", "ShowTopupsOnly"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Balance.Types.Msg", [], Types_Msg, () => [[["Item", PagingResult$1$reflection(Transaction$reflection())]], [["Item", AsyncOperationEvent$1$reflection(BalanceInformation$reflection())]], [["Item", Types_Transaction$reflection()]], [], [["Item1", Types_Transaction$reflection()], ["Item2", AsyncOperationEvent$1$reflection(Types_Transaction$reflection())]], [["Item", Types_Msg$reflection_1()]], [["Item", Types_Msg$reflection_2()]], [["Item", bool_type]]]);
}

export function State_load(customerId, page, filterTransactions) {
    return Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, partialApply(1, api().getTransactions, [customerId, 10, page]), filterTransactions, (arg0) => (new Types_Msg(0, arg0)));
}

export function State_loadBalanceInformation(customerId) {
    return singleton.Delay(() => singleton.Bind(api().getBalanceInformation(customerId), (_arg1) => singleton.Return(new Types_Msg(1, new AsyncOperationEvent$1(1, _arg1)))));
}

export function State_setInvoiceAsPaid(transaction) {
    return singleton.Delay(() => singleton.Bind(api().setInvoiceAsPaid(transaction.Id), (_arg1) => {
        const paidTransaction = _arg1;
        return singleton.Return(new Types_Msg(4, transaction, new AsyncOperationEvent$1(1, new Types_Transaction(paidTransaction.Id, paidTransaction, false, paidTransaction.ReceiptId))));
    }));
}

export function State_reloadCmd(customerId, filterTransactions) {
    return Cmd_batch(ofArray([Cmd_OfFunc_result(new Types_Msg(1, new AsyncOperationEvent$1(0))), State_load(customerId, 1, filterTransactions)]));
}

export function State_init(customerId) {
    return [new Types_State(customerId, State_init_1(customerId), new Deferred$1(0), State_init_2(1, 10, 1), new Deferred$1(0), void 0, false), State_reloadCmd(customerId, new TransactionFilter(1))];
}

export function State_update(state, msg) {
    const filterTransactions = state.ShowTopupsOnly ? (new TransactionFilter(0)) : (new TransactionFilter(1));
    if (msg.tag === 1) {
        if (msg.fields[0].tag === 1) {
            return [new Types_State(state.CustomerId, state.TopUp, state.Transactions, state.Paging, new Deferred$1(2, msg.fields[0].fields[0]), state.ConfirmMarkAsPaidRequest, state.ShowTopupsOnly), Cmd_none()];
        }
        else {
            return [new Types_State(state.CustomerId, state.TopUp, state.Transactions, state.Paging, new Deferred$1(1), state.ConfirmMarkAsPaidRequest, state.ShowTopupsOnly), Cmd_fromAsync(State_loadBalanceInformation(state.CustomerId))];
        }
    }
    else if (msg.tag === 2) {
        return [new Types_State(state.CustomerId, state.TopUp, state.Transactions, state.Paging, state.Information, msg.fields[0], state.ShowTopupsOnly), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [new Types_State(state.CustomerId, state.TopUp, state.Transactions, state.Paging, state.Information, void 0, state.ShowTopupsOnly), Cmd_none()];
    }
    else if (msg.tag === 4) {
        if (msg.fields[1].tag === 1) {
            return [new Types_State(state.CustomerId, state.TopUp, Deferred_map((x_3) => add(msg.fields[1].fields[0].Id, msg.fields[1].fields[0], x_3), state.Transactions), state.Paging, state.Information, state.ConfirmMarkAsPaidRequest, state.ShowTopupsOnly), Cmd_OfFunc_result(new Types_Msg(1, new AsyncOperationEvent$1(0)))];
        }
        else {
            return [new Types_State(state.CustomerId, state.TopUp, Deferred_map((x_2) => add(msg.fields[0].Id, new Types_Transaction(msg.fields[0].Id, msg.fields[0].Payment, true, msg.fields[0].ReceiptId), x_2), state.Transactions), state.Paging, state.Information, void 0, state.ShowTopupsOnly), Cmd_fromAsync(State_setInvoiceAsPaid(msg.fields[0]))];
        }
    }
    else if (msg.tag === 5) {
        const patternInput = State_update_1(state.TopUp, msg.fields[0]);
        const cmd = (patternInput[2] == null) ? Cmd_none() : State_reloadCmd(state.CustomerId, filterTransactions);
        return [new Types_State(state.CustomerId, patternInput[0], state.Transactions, state.Paging, state.Information, state.ConfirmMarkAsPaidRequest, state.ShowTopupsOnly), Cmd_batch(ofArray([Cmd_map((arg0) => (new Types_Msg(5, arg0)), patternInput[1]), cmd]))];
    }
    else if (msg.tag === 6) {
        const patternInput_1 = State_update_2(state.Paging, msg.fields[0]);
        const extCmd = patternInput_1[1];
        if (extCmd == null) {
            return [state, Cmd_none()];
        }
        else {
            return [new Types_State(state.CustomerId, state.TopUp, new Deferred$1(1), patternInput_1[0], state.Information, state.ConfirmMarkAsPaidRequest, state.ShowTopupsOnly), State_load(state.CustomerId, extCmd.fields[0], filterTransactions)];
        }
    }
    else if (msg.tag === 7) {
        if (msg.fields[0]) {
            return [new Types_State(state.CustomerId, state.TopUp, state.Transactions, state.Paging, state.Information, state.ConfirmMarkAsPaidRequest, false), State_load(state.CustomerId, 1, new TransactionFilter(1))];
        }
        else {
            return [new Types_State(state.CustomerId, state.TopUp, state.Transactions, state.Paging, state.Information, state.ConfirmMarkAsPaidRequest, true), State_load(state.CustomerId, 1, new TransactionFilter(0))];
        }
    }
    else {
        const s = State_init_2(msg.fields[0].Page, msg.fields[0].PageSize, msg.fields[0].Total);
        return [new Types_State(state.CustomerId, state.TopUp, new Deferred$1(2, ofList(map((x_1) => [x_1.Id, new Types_Transaction(x_1.Id, x_1, false, x_1.ReceiptId)], msg.fields[0].Items))), s, state.Information, state.ConfirmMarkAsPaidRequest, state.ShowTopupsOnly), Cmd_none()];
    }
}

export const View_mc = mc;

export const View_visa = visa;

export const View_amex = amex;

function View_toUrl(_arg1) {
    switch (_arg1) {
        case "mc": {
            return View_mc;
        }
        case "visa": {
            return View_visa;
        }
        case "amex": {
            return View_amex;
        }
        default: {
            return void 0;
        }
    }
}

export function View_card(card) {
    let cardType;
    const o_1 = map_1((_url) => (null), View_toUrl(card.CardType));
    cardType = ((o_1 == null) ? null : o_1);
    return columns(ofArray([new Option(1), new Option(3), new Option(4), new Option(10, singleton_1(new Modifier_IModifier(1, new Color_IColor(15))))]), ofArray([column(empty(), singleton_1(toText(printf("**%s"))(card.Last4Digits))), column(empty(), singleton_1(cardType))]));
}

export function View_invoice(_invoice) {
    return columns(ofArray([new Option(1), new Option(3), new Option(4), new Option(10, singleton_1(new Modifier_IModifier(1, new Color_IColor(15))))]), singleton_1(column(empty(), singleton_1("Invoice"))));
}

export function View_method(transaction) {
    const matchValue = transaction.Payment.Details;
    switch (matchValue.tag) {
        case 1: {
            return View_invoice(matchValue.fields[0]);
        }
        case 2: {
            return Helpers_nothing;
        }
        default: {
            return View_card(matchValue.fields[0]);
        }
    }
}

function View_noPayments() {
    return react.createElement("div", {}, "Customer has no payments");
}

export function View_amount(x) {
    return div(singleton_1(new Option_1(6, singleton_1(new Modifier_IModifier(2, new TextWeight_Option(3))))), singleton_1(Printer_sekM(x)));
}

export function View_utcDateStr(x) {
    let arg10;
    let copyOfStruct = specifyKind(x, 1);
    arg10 = toString(copyOfStruct, "yyyy-MM-dd HH:mm:ss");
    return toText(printf("%s"))(arg10);
}

export function View_dateAndTime(x) {
    return div(empty(), singleton_1(View_utcDateStr(x)));
}

export function View_invoiceDetails(invoice) {
    const matchValue = invoice.Status;
    switch (matchValue.tag) {
        case 1: {
            return "Paid";
        }
        case 3: {
            return "Canceled";
        }
        case 2: {
            return "Overdue";
        }
        default: {
            return "Unpaid";
        }
    }
}

export function View_details(transaction) {
    return div(singleton_1(new Option_1(6, singleton_1(new Modifier_IModifier(1, new Color_IColor(15))))), toList(delay(() => {
        const matchValue = transaction.Payment.Details;
        switch (matchValue.tag) {
            case 1: {
                return singleton_2(View_invoiceDetails(matchValue.fields[0]));
            }
            case 2: {
                return singleton_2(Helpers_nothing);
            }
            default: {
                return singleton_2(matchValue.fields[0].ExternalId);
            }
        }
    })));
}

export function View_invoiceNumber(transaction) {
    const matchValue = transaction.ReceiptId;
    if (matchValue == null) {
        return "";
    }
    else {
        return matchValue;
    }
}

export function View_comment(transaction) {
    return div(empty(), toList(delay(() => {
        const matchValue = transaction.Payment.Details;
        switch (matchValue.tag) {
            case 1: {
                return singleton_2("");
            }
            case 2: {
                return singleton_2(defaultArg(matchValue.fields[0], ""));
            }
            default: {
                return singleton_2("");
            }
        }
    })));
}

export function View_action(transaction, dispatch) {
    const matchValue = transaction.Payment.Details;
    switch (matchValue.tag) {
        case 2: {
            return Helpers_nothing;
        }
        case 1: {
            const matchValue_1 = matchValue.fields[0].Status;
            switch (matchValue_1.tag) {
                case 0:
                case 2: {
                    return button(ofArray([new Option_2(1, new Size_ISize(0)), new Option_2(4), new Option_2(13, transaction.IsMarkAsPaidInProgress), new Option_2(18, (x_1) => {
                        x_1.preventDefault();
                        dispatch(new Types_Msg(2, transaction));
                    })]), singleton_1("Mark as paid"));
                }
                default: {
                    return Helpers_nothing;
                }
            }
        }
        default: {
            return Helpers_nothing;
        }
    }
}

function View_transactionTable(x, dispatch) {
    return table_2(singleton_1(new TableOption(2)), ofArray([react.createElement("thead", {}, react.createElement("tr", {}, react.createElement("th", {}, "Date \u0026 time"), react.createElement("th", {}, "Amount"), react.createElement("th", {}, "Method"), react.createElement("th", {}, "Details"), react.createElement("th", {}, "Invoice Number"), react.createElement("th", {}, "Comment/Action"))), react.createElement("tbody", {}, ...toList(delay(() => map_2((transaction) => react.createElement("tr", {}, react.createElement("td", {}, View_dateAndTime(transaction.Payment.CreatedUtcTime)), react.createElement("td", {}, View_amount(transaction.Payment.Amount)), react.createElement("td", {}, View_method(transaction)), react.createElement("td", {}, View_details(transaction)), react.createElement("td", {}, View_invoiceNumber(transaction)), react.createElement("td", {}, View_comment(transaction), View_action(transaction, dispatch))), reverse(map((tuple) => tuple[1], toList_1(x)))))))]));
}

export function View_renderLoading(loading) {
    if (loading) {
        return hero(empty(), singleton_1(body(empty(), singleton_1(container(ofArray([new Option_3(0), new Option_3(5, singleton_1(new Modifier_IModifier(5, new Screen(0), new TextAlignment_Option(0))))]), singleton_1(h1(empty())(singleton_1(Fa_i(ofArray([new Fa_IconOption(11, "fas fa-spinner"), new Fa_IconOption(12)]), [])))))))));
    }
    else {
        return null;
    }
}

export function View_renderTransactions(state, dispatch) {
    const matchValue = state.Transactions;
    let pattern_matching_result;
    if (matchValue.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (matchValue.tag === 2) {
        if (FSharpMap__get_Count(matchValue.fields[0]) === 0) {
            pattern_matching_result = 1;
        }
        else {
            pattern_matching_result = 2;
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return View_renderLoading(true);
        }
        case 1: {
            return View_noPayments();
        }
        case 2: {
            if (matchValue.tag === 2) {
                return View_transactionTable(matchValue.fields[0], dispatch);
            }
            else {
                throw (new Error("Match failure"));
            }
        }
    }
}

export function View_renderInfo(state) {
    const matchValue = state.Information;
    switch (matchValue.tag) {
        case 1: {
            return ["children", "Loading ..."];
        }
        case 2: {
            return ["children", Interop_reactApi.Children.toArray([renderKeyValue("Total amount spent", "-"), renderKeyValue("Current balance", Printer_sekM(matchValue.fields[0].Balance)), renderKeyValue("Auto-top up enabled", "-"), renderKeyValue("Monthly limit", "-"), renderKeyValue("Current month spent", "-")])];
        }
        default: {
            return ["children", "Not loaded"];
        }
    }
}

export function View_renderConfirmDialog(state, dispatch) {
    const matchValue = state.ConfirmMarkAsPaidRequest;
    if (matchValue == null) {
        return Helpers_nothing;
    }
    else {
        const transaction = matchValue;
        return modal(singleton_1(new Option_4(1, true)), ofArray([background(empty(), empty()), Card_card(empty(), ofArray([Card_head(empty(), ofArray([Card_title(empty(), singleton_1("Confirmation")), delete$(singleton_1(new Option_5(3, (x) => {
            x.preventDefault();
            dispatch(new Types_Msg(3));
        })), empty())])), Card_body(empty(), singleton_1(content(empty(), ofArray([renderKeyValue("Invoice Id", int32ToString(transaction.Payment.Id)), renderKeyValue("Date", View_utcDateStr(transaction.Payment.CreatedUtcTime)), renderKeyValue("Amount", Printer_sekM(transaction.Payment.Amount))])))), Card_foot(empty(), ofArray([button(ofArray([new Option_2(0, new Color_IColor(6)), new Option_2(18, (x_2) => {
            x_2.preventDefault();
            dispatch(new Types_Msg(4, transaction, new AsyncOperationEvent$1(0)));
        })]), singleton_1("Mark as paid")), button(singleton_1(new Option_2(18, (x_3) => {
            x_3.preventDefault();
            dispatch(new Types_Msg(3));
        })), singleton_1("Cancel"))]))]))]));
    }
}

export function View_render(state, dispatch) {
    let props_4, props, props_2;
    return react.createElement("div", {}, (props_4 = singleton_1(["children", Interop_reactApi.Children.toArray([(props = singleton_1(View_renderInfo(state)), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_2 = ofArray([["className", "is-4-desktop"], ["children", Interop_reactApi.Children.toArray([View_render_1(state.TopUp, (arg) => {
        dispatch(new Types_Msg(5, arg));
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_4)))), columns(empty(), singleton_1(column(singleton_1(new Option_6(0, new Screen(0), new ISize(8))), singleton_1(switch$(ofArray([new Option_7(6, state.ShowTopupsOnly), new Option_7(12, "show-topups-only"), new Option_7(10, (x) => {
        dispatch(new Types_Msg(7, state.ShowTopupsOnly));
        x.preventDefault();
    })]), singleton_1("Show Topups Only")))))), View_renderTransactions(state, dispatch), View_renderConfirmDialog(state, dispatch), div_1(empty(), singleton_1(div(empty(), singleton_1(View_render_2(state.Paging, (arg_1) => {
        dispatch(new Types_Msg(6, arg_1));
    }))))));
}

